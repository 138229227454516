const isIE = (navigator.appVersion.indexOf('MSIE') !== -1);

// TODO: Cleanup this file after first priority tasks

export const Browser = {
  isIE() {
    // @ts-ignore
    return /* @cc_on!@ */false || !!document.documentMode;
  },
  isFirefox() {
    // @ts-ignore
    return typeof InstallTrigger !== 'undefined';
  },
  isChrome() {
    // @ts-ignore
    return !!window.chrome && /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && !/OPR/.test(navigator.userAgent);
  },
  isEdge() {
    return !isIE && !!(window as any).StyleMedia;
  },
  isiOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
  },
  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  },
  isAndroid() {
    return navigator.userAgent.toLowerCase()
      .indexOf('android') > -1;
  },
  isSafariWebRTC() {
    return navigator.mediaDevices && Browser.isSafari();
  },
};

export enum OpenURLTarget {
  self = '_self',
  blank = '_blank',
}

export const openURL = (url: string, target: OpenURLTarget = OpenURLTarget.self) => {
  const isWithProtocol = url.startsWith('http');
  const redirectTo = isWithProtocol ? url : `http://${url}`;

  window.open(redirectTo, target);
};

export const isMobile = () => (
  navigator.userAgent.match(/Android|Mobile|IEMobile|Opera Mini|iPhone|iPad|iPod/i) !== null
);
